














































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import {
  BContainer,
  BCardBody,
  BCardTitle,
  BCardText,
  BCard,
  BCol,
  BRow,
  BButton,
  BForm,
  BFormCheckbox,
  BBadge,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormTextarea,
  BSidebar,
  VBToggle
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VSelect from 'vue-select'
import AdminFormServicePrices from '@/views/admin/services/AdminFormServicePrices.vue'

@Component({
  name: 'AdminFormServiceSidebar',
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BContainer,
    BRow,
    BCol,
    BButton,
    BForm,
    BBadge,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    AdminFormServicePrices,
    BSidebar,
    BFormTextarea,
    VSelect
  },

  directives: {
    'b-toggle': VBToggle
  }
})
export default class AdminFormServiceSidebar extends Vue {
  @Prop({ default: 'Customer' }) userRole: string
  @Prop() serviceData: object
  @Prop() mode: string

  private selectedServicePrice: Object = {}
  private sericePriceMode: String = 'add'

  private boolAddServicePrices: Boolean = false
  private models: String[] = ['Provider', 'Services']
  private serviceCategory : object = [
    { name: 'Car', slug: 'car' },
    { name: 'Boat', slug: 'boat' },
    { name: 'Motorcycle', slug: 'motorcycle' }
  ]
  private providerCategory : object = [
    { name: 'Vehicle Service List', slug: 'vehicle_service_list' }
  ]
  private form : any = {
    title: '',
    subTitle: '',
    model: '',
    category: '',
    servicePrices: []
  }

  get categories (): object {
    this.form.category = ''
    if (this.form.model.toUpperCase() === 'PROVIDER') {
      return this.providerCategory
    }
    return this.serviceCategory
  }

  @Watch('serviceData', { deep: true })
  private changeServiceData () {
    this.form = this.serviceData
  }
  @Watch('mode', { deep: true })
  private changeMode () {
    if (this.mode === 'update') {
      this.form = this.serviceData
    } else {
      this.form = {
        title: '',
        subTitle: '',
        model: '',
        category: '',
        servicePrices: []
      }
    }
  }
  // METHODS
  private addNewServicePrice (servicePrice: Object) {
    this.form.servicePrices.push(servicePrice)
  }

  private passServicePriceData (price) {
    this.sericePriceMode = 'update'
    this.selectedServicePrice = price
  }
}
