




























































import { Component, Vue, Emit, Watch, Prop } from 'vue-property-decorator'
import {
  BContainer,
  BCardBody,
  BCardTitle,
  BCardText,
  BCard,
  BCol,
  BRow,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormTextarea,
  BSidebar,
  VBToggle
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VSelect from 'vue-select'
import { mixins } from 'vue-class-component'
import MixinFunctions from '@/mixins/mixinFunctions'

@Component({
  name: 'AdminEditUserSidebar',
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BContainer,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    BFormTextarea,
    VSelect
  },
  directives: {
    'b-toggle': VBToggle
  }
})

export default class AdminFormServicePrices extends mixins(Vue, MixinFunctions) {
   @Prop({ default: 'add' }) mode : String
   @Prop() servicePriceData : Object

  private carServicePrices : object = [
    { slug: 'sedan', name: 'Sedan' },
    { slug: 'suv', name: 'SUV' },
    { slug: 'pickup', name: 'Pickup' },
    { slug: 'coupe', name: 'Coupe' },
    { slug: 'convertible', name: 'Convertible' },
    { slug: 'wagon', name: 'Wagon' },
    { slug: 'van', name: 'Van/Minivan' },
    { slug: 'hatchback', name: 'Hatchback' }
  ]
  private formServicePrices: Object = {}

  private created () {
    this.handleFormServicePrices(this.mode)
  }

  @Watch('mode', { deep: true })
  private onChangeMode (val) {
    this.handleFormServicePrices(val)
  }

  private handleFormServicePrices (mode: String) {
    if (mode === 'add') {
      this.formServicePrices = {
        category: null,
        country: null,
        price: null
      }
    } else {
      this.formServicePrices = this.servicePriceData
    }
  }

  @Emit()
  addServicePrice () {
    let servicePrice = { ...this.formServicePrices }
    this.formServicePrices = {
      category: null,
      country: null,
      price: null
    }
    return servicePrice
  }
}
