
















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  BBadge,
  BLink,
  BFormInput,
  BPagination,
  BTable,
  BCard,
  BSpinner,
  BMedia,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BButton,
  BSidebar,
  VBToggle
} from 'bootstrap-vue'
import VSelect from 'vue-select'

import { defaultPagination, Pagination } from '@/models/General'
import { Action } from '@/store/decorators'
import { avatarText } from '@core/utils/filter'
import { UserRoleEnums } from '@/enums/User'
import {
  defaultParamsPayload,
  ModelPayload,
  ParamsPayload
} from '@/models/Payloads'
import AdminEditServiceSidebar from '@/views/admin/services/AdminFormServiceSidebar.vue'
import { ServiceModule } from '@/store/modules/serviceModule'
import { mixins } from 'vue-class-component'
import MixinFunctions from '@/mixins/mixinFunctions'

@Component({
  name: 'AdminServicesList',
  components: {
    BBadge,
    BLink,
    BFormInput,
    BPagination,
    BTable,
    BCard,
    BSpinner,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BButton,
    BSidebar,
    VSelect,
    AdminEditServiceSidebar
  },
  directives: {
    'b-toggle': VBToggle
  }
})
export default class AdminServicesList extends mixins(Vue, MixinFunctions) {
  @Action(ServiceModule, 'fetchAdminServices') private fetchAdminServices!: (payload: ModelPayload) => Promise<Pagination>

  $refs!: { refServicesTable }

  private fields: object = [
    { key: 'title' },
    { key: 'subTitle' },
    { key: 'model' },
    { key: 'addOn', label: 'Type' },
    { key: 'category' },
    { key: 'status', label: 'Status' },
    { key: 'actions', thStyle: { width: '1%' } }
  ]

  private servicePagination: Pagination = defaultPagination()
  private avatarText: object = avatarText
  private userRole: String = 'Services'
  private paramsPayload: ParamsPayload = defaultParamsPayload()
  private perPageOptions: number[] = [10, 25, 50, 100]
  private totalData: number = 0
  private searchQuery: string = ''
  private searchQueryTimeout: any = null

  private created () {
    this.paramsPayload = this.loadQueryString(this.paramsPayload)
  }

  private async loadServices () {
    try {
      this.servicePagination = await this.fetchAdminServices({
        params: this.paramsPayload
      })

      this.totalData = this.servicePagination.total

      return this.servicePagination.data
    } catch (error) {
      // console.error(error)
    }
  }

  private serviceData: Object = {}
  private formMode: String = 'add'

  private passServideData (serviceData) {
    this.serviceData = serviceData
    this.formMode = 'update'
  }

  get dataMeta () {
    const localItemsCount = this.$refs.refServicesTable ? this.$refs.refServicesTable.localItems.length : 0

    return {
      from: this.paramsPayload.perPage * (this.paramsPayload.page - 1) + (localItemsCount ? 1 : 0),
      to: this.paramsPayload.perPage * (this.paramsPayload.page - 1) + localItemsCount,
      of: this.totalData
    }
  }

  @Watch('paramsPayload', { deep: true, immediate: false })
  private refreshTable () {
    clearTimeout(this.searchQueryTimeout)
    this.$refs.refServicesTable.refresh()
  }

  @Watch('searchQuery')
  private onSearchQuery () {
    this.paramsPayload.q = this.searchQuery
    clearTimeout(this.searchQueryTimeout)

    this.searchQueryTimeout = setTimeout(() => {
      this.refreshTable()
    }, 1000)
  }
}
